@import "src/styles/variables";

.tabs {
  &__content {
    &._active {
      display: flex;
    }
  }
}

.girlfriend-block{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 615px;

  @media (max-width: $mobileSmall) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
.check-profile__wrapper {
  padding-top: 16px;
  padding-bottom: 16px;

  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg-main);
  z-index: 10;
  border-top: 1px solid var(--color-400);
  border-radius: 20px 20px 0px 0px;

  .model__container {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 8px;
      .button {
        min-width: unset;
        width: 100%;
      }
    }
  }
}
.model {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: $mobile) {
    padding-bottom: 30px;
    gap: 24px;
    &.ssr{
      margin-top: 250px;
    }
  }

  ~ .new-girls__container {
    padding-bottom: 130px;
    @media (max-width: $mobile) {
      padding-bottom: 24px;
    }


    .top-models-ssr{
      display: grid;
      grid-template-columns: repeat(4, 1fr );
      grid-gap: 12px;

      @media (max-width: $mobile) {
        grid-template-columns: 100%;
      }
    }
  }

  .model__title {
    margin-bottom: 20px;
  }

  &__review {
    display: flex;
    gap: 4px;
    padding: 4px;
    border-radius: 4px;
    background-color: var(--color-200);
    align-items: center;
    text-decoration: none;

    > span:not(.rating) {
      white-space: nowrap;
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }

  &__reviews {
    grid-row: 3 / 4;
    grid-column: 1/3;
    @media (max-width: $tablet) {
      grid-column: 1/2;
    }

    @media (max-width: $tablet) and (min-width: $tabletSmall) {
      grid-column: 1/2;
      grid-row: 4 / 5;
    }

    &__top {
      @media (max-width: $tabletSmall) {
        > div {
          width: 100%;

          .button {
            width: 100%;
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 276px;
    height: max-content;
    @media (min-width: $tabletSmall) {
      padding: 12px 8px;
      background-color: var(--color-200);
      border-radius: 4px;
    }

    @media (max-width: $tablet) {
      width: 450px;
    }

    @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
      grid-column: 2 / 3;
    }

    @media (max-width: $tabletSmall) {
      width: 100%;
    }

    p {
      margin-bottom: 24px;
    }

    .model__icons {
      margin-bottom: 12px;

      .tag{
        padding: 6px;
      }
    }

    .model__icon {
      background-color: var(--green-400);
    }

    &-bottom {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      @media (max-width: $tabletSmall) {
        gap: 12px;
      }
      .girl-card__speak {
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        height: auto;
        line-height: 140%;
        cursor: default;
      }
    }
  }

  &-card {

    &__parties {
      @media (max-width: $tabletSmall) {
        max-width: 100%;
      }
    }

    &__wrapper {
      display: flex;
      gap: 32px;
      @media (max-width: $pc) {
        gap: 24px;
      }
      @media (max-width: $mobile) {
        flex-direction: column;
      }

      .party-card {
        width: 100%;
        max-width: 482px;
      }
      @media (min-width: $tabletSmall) {
        .girl-card{
          height: 660px;
        }
      }
    }
  }

  .schedule-table {
    @media (max-width: $tablet) {
      flex-direction: column;
    }

    &__work-time {
      @media (max-width: $mobileSmall) {
        width: 100%;
      }
    }
  }

  &-extras {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    &__item{
      padding: 6px 12px;
      display: flex;
      gap: 4px;
      align-items: center;
      min-height: 50px;
      border-radius: 4px;
      background-color: var(--color-300);

      i{
        font-size: 34px;
      }
    }

    @media (max-width: $mobileExtraSmall) {
      flex-direction: column;
    }

    .button {
      gap: 8px;
      line-height: 130%;
      color: var(--color-main);
      justify-content: flex-start;
    }

    .button-icon{
      width: fit-content;
      i{
        color: var(--green-main);
      }
      @media (max-width: $mobile) {
        margin-left: auto;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;

      > span {
        color: var(--color-600);
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    .button {
      justify-content: center;
    }
    .button_outline--green{
      padding: 14px;
    }
  }

  &__interactions {
    display: flex;
    flex-direction: column;
    gap: 48px;
    @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
      flex-direction: row;
    }
    @media (max-width: $mobile) {
      flex-direction: column;
    }
    @media (max-width: $mobile) {
      gap: 20px;
      flex-direction: column;
    }
    @media (max-width: $mobileSmall) {
      gap: 12px;
    }

    > .button {
      @media (max-width: $tabletSmall) {
        font-size: 13px;
        padding: 10px 4px;
      }
      @media (max-width: $mobileExtraSmall) {
        font-size: 12px;
        gap: 4px;
      }

      span {
        font-size: 13px;
        height: auto;
        @media (max-width: $tabletSmall) {
          font-size: 12px;
        }
        @media (max-width: $mobileExtraSmall) {
          font-size: 11px;
          height: auto;
        }
      }
    }
  }

  &-header {
    color: var(--color-main);
    position: relative;

    &__breadcrumbs {
      margin-right: auto;
      margin-bottom: 16px;
      @media (min-width: $mobile) {
        height: 32px;
      }
    }

    &__back {
      margin-right: auto;
      margin-bottom: 16px;
      display: flex;
      width: fit-content;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      text-decoration: none;
      @media (min-width: $mobile) {
        height: 32px;
        padding-right: 280px;
      }
    }

    &__body {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-template-rows: 1fr auto;
      align-items: center;
      grid-gap: 0 12px;
      @media (max-width: $tablet) {
        grid-gap: 12px;
      }
      @media (max-width: $tabletSmall) {
        grid-template-columns: auto 1fr;
      }
      @media (max-width: $mobile) {
        grid-gap: 4px;
      }

      &.no-stories {
        grid-template-columns: 1fr auto;
      }

      .stories-circle__button{
        @media (max-width: $mobile) {
          width: 70px;
        height: 70px; 
        &::before{
          width: 68px;
          height: 68px;
        }

        .stories-circle__image{
          img{
            width: 66px;
            height: 66px;
          }
          
        }
        }
        
      }
    }

    &__interactions {
      display: flex;
      gap: 8px;
      margin-left: auto;
      justify-content: flex-end;
      @media (min-width: $mobile) {
        position: absolute;
        top: 0;
        right: 0;
      }
      @media (max-width: $mobile) {
        width: 60%;
      }
      @media (max-width: $mobileExtraSmall) {
        width: 50%;
      }
    }

    &__wrapper {
      display: flex;
      gap: 16px;
      flex-direction: column;
      grid-row: 1 / 3;
      @media (min-width: $tabletSmall) {
        padding: 8px 0;
      }
    }

    &__stories {
      width: 106px;
      height: 106px;
      margin-bottom: 0;
      grid-column: 1/2;
      grid-row: 1 / 4;
      @media (max-width: $tablet) {
        grid-row: 1 / 3;
      }
      

      &:before {
        width: 100px;
        
      }

      .stories-circle__image {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        z-index: 2;
        min-width: 100px;
        width: 100px;
        @media (max-width: $mobile) {
          min-width: 68px;
          width: 68px;
        }

        ._empty {
          background-color: red;
        }
      }
    }

    &__box {
      display: flex;
      gap: 16px;
      align-items: center;
      @media (max-width: $tablet) {
        grid-row: 1 / 2;
        //grid-column: 2 / 3;
        gap: 8px;
      }
      @media (max-width: $tabletSmall) {
        grid-row: 1 / 3;
        flex-wrap: wrap;
      }
      @media (max-width: $mobile) {
        gap: 16px;
        grid-row: 1 / 2;
      }

      &-info {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;

        &._adit {
          flex-direction: row;
          align-items: center;

          @media (max-width: $mobile) {
            justify-content: space-between;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;
      gap: 12px;
      grid-column: 2 / 4;
      grid-row: 2 / 3;
      line-height: 1;
      @media (max-width: $tablet) {
        grid-row: 2 / 3;
      }
      @media (max-width: $tabletSmall) {
        grid-column: 1 / 4;
        grid-row: 3/4;
      }
      @media (max-width: $mobile) {
        grid-column: 1 / 3;
        grid-row: 3/ 4;
        flex-wrap: wrap;
      }

      .no-stories & {
        grid-column: 1 / 3;
      }
    }

    &__distance {
      text-decoration: none;
      width: max-content;
      @media (max-width: $mobile) {
        min-width: 90px;
      }
    }

    &__address,
    &__distance,
    &__last-visit {
      position: relative;
      padding-left: 18px;

      i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__last-visit {
      i {
        width: 14px;
        height: 14px;
      }
    }

    &__status {
      min-width: 168px;
      width: 168px;
      height: 60px;
      position: relative;

      img {
        position: absolute;
        object-fit: contain;
        object-position: center center;
        width: 100%;
        height: 100%;
      }

      @media (max-width: $tablet) {
        margin-right: auto;
      }

      @media (max-width: $tabletSmall) {
        height: 52px;
        margin-top: -22px;
      }

      @media (max-width: $mobile) {
        min-width: 94px;
        width: 94px;
        height: 34px;
        margin: 0;
      }
    }

    &__address {
      line-height: 20px;
    }

    .model__name {
      margin: 0;
      padding: 0;
    }

    &__age {
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: var(--black-400);
      border-radius: 4px;
      min-height: 36px;
      font-size: 13px;
      font-weight: 400;
      color: var(--color-700);
      

      span {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-main);
        @media (max-width: $mobile) {
          font-size: 18px;
          flex-direction: column;
          gap: 0;
          padding: 4px 4px 0px 4px;
        }
      }

      @media (max-width: $mobile) {
        font-size: 13px;
        flex-direction: column;
        gap: 0;
        padding: 0 4px;
        line-height: 120%;
        justify-content: center;
      }
    }

    .model__buttons {
      grid-row: 1/2;
      grid-column: 3/4;

      @media (max-width: $tabletSmall) {
        grid-row: 1 / 4;
        grid-column: 3 / 4;
      }

      @media (max-width: $mobile) {
        grid-row: 4 / 5;
        grid-column: 1 / 3;
        margin-top: 14px;
      }

      .no-stories & {
        grid-column: 2 / 3;
        @media (max-width: $mobile) {
          grid-column: 1 / 3;
        }
      }
    }
  }

  &-main {

    &.ssr{
      display: grid;
      grid-template-columns: 40% 25% auto;

      .all-photo-container {
        height: unset;
        max-height: 650px;

        .photo-wrapper{
          width: 100%;
          aspect-ratio: 3 / 4;
          height: 100%;
        }

        img{
          width: 100%;
        }
      }

      .schedule-table, .model-extras{
        grid-column: span 2
      }

      .model__info{
        width: unset;
        max-width: 500px;
      }

      @media (max-width: $mobile) {
        grid-gap: 24px 18px;
        grid-template-columns: 100%;
      }
    }
    display: grid;
    grid-template-columns: 482px 1fr;
    grid-gap: 40px 32px;
    @media (max-width: $pc) {
      grid-template-columns: 400px 1fr;
      grid-gap: 40px 18px;
    }

    @media (max-width: $tabletSmall) {
      grid-template-columns: minmax(286px, 1fr) 258px;
    }

    @media (max-width: $mobileSmall) {
      grid-template-columns: 100%;
      grid-gap: 24px 18px;
    }

    &__sliders {

      .tabs{
        .swiper{
          img{
            object-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
    
            @media (max-width: $mobile) {
              object-fit: contain;
            }
        }
      }
      
      }

      @media (max-width: $tabletSmall) and (min-width: $mobile) {
        grid-column: 1/2;
      }
    }

    &__slider-video {
      min-height: auto;
      border-radius: 4px;
      height: 100%;

      video {
        object-fit: contain;
      }
    }

    &__private,
    &__thumb {
      .swiper-slide {
        border-radius: 4px;
        overflow: hidden;
      }
    }

    > .tabs {
      @media (max-width: $tabletSmall) {
        grid-column: 1 / 2;
        grid-row: 1/2;
      }

      .tabs__content {
        &._active {
          display: block;
        }

        &._initial {
          .swiper-slide:first-child {
            display: none;
          }
        }
      }

      span {
        @media (max-width: $mobile) {
          display: none;
        }
      }
    }

    &__slider {
      margin-bottom: 24px;
      aspect-ratio: 3 / 4;

      img{
        border-radius: 4px;
      }

      & .stories__slide {
        cursor: zoom-in;

        .icon--play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: var(--white-main);
          z-index: 1;
        }

        .video {
          height: 100%;
          border-radius: 0;
        }

        @media (max-width: $mobileSmall) {
          cursor: default;
        }
      }
    }

    &__thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }

      .swiper {
        margin: 0 30px;
        width: 100%;
        @media (min-width: $tabletSmall) {
          max-width: 422px;
          height: 108px;
        }
        @media (max-width: $mobileSmall) {
          height: 108px;
        }
      }

      .swiper-slide {
        cursor: pointer;
        border: 1px solid transparent;
        width: 102px;
        height: 108px;
        @media (max-width: $tabletSmall) {
          aspect-ratio: 3 / 4;
        }

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 44px;
          color: var(--white-main);
          z-index: 1;
        }

        &-thumb-active {
          border: 1px solid var(--color-main);
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        transform: unset;
        height: 100%;
        width: 24px;
        top: 0;
        border-radius: 4px;

        i {
          position: absolute;
          aspect-ratio: 1/1;
          width: 28px;
        }
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-button-next {
        right: 0;
      }
    }

    .model-card {
      @media (max-width: $mobile) {
        max-width: 482px;
        grid-column: auto;
        grid-row: 6 / 7;
      }
      @media (max-width: $mobile) {
        max-width: 100%;
        grid-column: auto;
        grid-row: 6/7;
      }
      @media (max-width: $mobileSmall) {
        grid-column: auto;
        grid-row: auto;
      }
    }

    &__private {
      grid-column: 1 / 2;
      width: 100%;
      position: relative;
      height: 170px;
      @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
        grid-column: 1 / 3;
        grid-row: 3/4;
      }
      @media (max-width: $mobile) {
        height: 184px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 138px;
        height: 264px;
        top: calc(50%);
        transform: translateY(-50%);
        right: -65px;
        background: var(--color-main-reverse);
        filter: blur(32px);
        border-radius: 64px 0 0 64px;
        z-index: 2;
        pointer-events: none;
      }

      &-slide {
        aspect-ratio: 3/4;
        position: relative;
        cursor: pointer;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: center;
        }

        .icon--triangle {
          position: absolute;
          right: 8px;
          top: 8px;
          color: var(--white-main);
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        height: 40px;
        transform: translateY(-50%);
        width: 28px;
        border-radius: 4px;
        display: flex;

        i {
          position: absolute;
          aspect-ratio: 1/1;
          width: 18px;
        }

        &[disabled] {
          opacity: 0;
        }
      }
    }

    .model__info {
      display: none;
      @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
        display: flex;
      }
    }
    

    &._edit {
      .model-card {
        @media (max-width: $tabletSmall) {
          grid-row: auto;
        }
      }
    }

    &__wrapper {
      grid-column: 1/3;
      display: flex;
      flex-direction: column;
      gap: 48px;
      @media (max-width: $tablet) {
        grid-column: 1/2;
      }
      @media (max-width: $mobileSmall) {
        gap: 24px;
      }

      .model__buttons {
        @media (max-width: $mobileSmall) {
          margin: 16px 0;
        }

        .button-icon {
          min-height: 50px;
          min-width: 50px;
          i{
            font-size: 24px;
          }

          @media (max-width: $mobile ){
            min-height: 44px;
            min-width: 44px;
          i{
            font-size: 18px;
          }
          }
        }

        

        .model-btn__videochat {
          min-width: 60px;
          @media (min-width: $tablet) {
            width: 100%;
          }
          @media (max-width: $mobile) {
            min-width: 40px;
          }
        }

        .model-btn__tel {
          min-width: 60px;
          @media (min-width: $tablet) {
            width: 100%;
          }
          @media (max-width: $mobile) {
            min-width: 40px;
          }
        }
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: 276px 1fr;
    grid-template-rows: min-content;
    grid-gap: 48px 32px;
    grid-column: 2 / 3;
    grid-row-start: 1;
    grid-row-end: 5;
    @media (max-width: $pc) {
      grid-gap: 48px 12px;
    }

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }

    @media (max-width: $tabletSmall) {
      grid-row-start: 2;
    }

    @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
      grid-column: 1 / 3;
      grid-gap: 24px 18px;
    }

    @media (max-width: $mobileSmall) {
      grid-column: 1/2;
    }

    &__top {
      display: flex;
      gap: 32px;
    }

    .tabs {
      &__nav {
        display: none;
        @media (max-width: $mobileSmall) {
          display: flex;
          grid-column: 1/2;
          grid-row: 1/2;
          margin-bottom: -8px;
        }
      }

      &__panel {
        display: flex;
        @media (max-width: $mobileSmall) {
          display: none;
        }
        @media (max-width: $mobileSmall) {
          grid-column: 1/2;
          grid-row: 2/3;
          &--active {
            display: flex;
          }
        }
      }
    }

    .model__info {
      @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
        display: none;
      }
    }

    &__cards {
      display: grid;
      gap: 24px;

      &:empty {
        display: none;
      }

      @media (min-width: $tabletSmall) {
        grid-template-columns: minmax(auto, 400px) 1fr;
      }

      @media (min-width: $pc) {
        gap: 32px;
        grid-template-columns: minmax(auto, 482px) 1fr;
      }

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__icons {
    display: flex;
    gap: 4px;
    height: auto;
    flex-wrap: wrap;
  }

  &__icon {
    background-color: var(--green-300);
    display: inline-flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    min-height: 28px;
    min-width: 28px;
    border-radius: 4px;
    cursor: help;
    color: var(--green-main);
    padding: 2px 6px 2px 2px;

    span {
      font-size: 15px;
      @media (max-width: $mobile) {
        font-size: 13px;
      }
    }
  }

  &__params {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px 4px;
    justify-content: space-between;
    margin-bottom: 16px;

    &.agency__params-filter {
      width: 100%;
      column-gap: 32px;
      row-gap: 16px;

      .filter-select-block{
        width: 100%;
        display: grid;
        grid-template-columns: auto 150px;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: 760px) {
    &__params {
      grid-template-columns: repeat(2, 1fr);
      &.agency__params-filter {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  &__param {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 13px;
    @media (max-width: $mobile) {
      font-size: 12px;
    }

    span {
      font-size: 15px;
      color: var(--color-main);

      @media (max-width: $mobile) {
        font-size: 14px;
      }

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__lang {
    display: flex;
    gap: 6px;
    color: var(--color-main);
    font-size: 15px;
    @media (max-width: $mobile) {
      font-size: 14px;
    }

    .icon-flag {
      cursor: default;
    }
  }

  .body-shape {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 12px;
    width: 70px;
    position: relative;
    background-color: var(--color-200);
    gap: 12px;
    height: 130px;
    padding: 6px;
    min-width: 110px;

    span {
      min-height: 13px;
      color: var(--color-main);
      display: flex;
      line-height: 1;
      align-items: center;
      background-color: var(--color-300);
      border-radius: 4px;
      font-size: 15px;
      font-weight: 400;
      padding: 4px 8px;

      &:last-of-type {
        margin-top: auto;
      }
    }

    img {
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -57%);

      position: absolute;
      top: 10px;
      width: 100px;
    }
  }

  &__serfices {
    display: flex;
    flex-direction: column;
    @media (max-width: $tablet) {
      grid-row: 2/3;
    }
    @media (max-width: $tabletSmall) and (min-width: $mobileSmall) {
      grid-row: 1/2;
    }

    h2 {
      margin-top: 0;
    }

    h3 {
      margin: 0;
    }

    .model__icon {
      padding: 0;
    }

    &-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: start;
      align-items: center;

      li {
        transition: background-color 0.1s ease-in 0s;
        max-width: 270px;

        &._active {
          background-color: var(--color-400);
          padding: 8px;
          border-radius: 4px;

          &:before {
            top: 9px;
          }
        }
      }
    }
    

    .services-top-container{
      display: flex;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 12px;

      @media (max-width: $mobile) {
        flex-wrap: wrap;
      }
      
      h3{
        order: 1;
        flex-grow: 1;
        flex-basis: 50%;
      }
      
      .girl-card__prices.model-page-prices{
        order: 2;
        flex-grow: 1;
        flex-basis: 50%;
        padding: 0px;   
      }

    &:has(h2){
        h2{
          order: 1;
          flex-grow: 1;
          // max-width: 50%;
        }
  
        h3{
          order: 3;
          flex-grow: 1;
          // max-width: 50%;
        }
        
        .girl-card__prices.model-page-prices{
          order: 2;
          flex-grow: 1;
          flex-basis: 50%;
        }
      }
      
    }
  }

  &__counters {
    display: flex;
    justify-content: center;
    gap: 8px;
    font-size: 24px;
    color: var(--color-main);
    @media (max-width: $mobile) {
      font-size: 18px;
    }
  }

  &__counter {
    min-height: 70px;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--color-200);
    width: 286px;

    span {
      color: var(--color-600);
    }
  }

  .agency-card {
    padding: 20px;
    background-color: var(--white-200);
    gap: 20px;
    @media (max-width: $mobile) {
      padding: 16px;
    }

    &__content {
      padding: 0;
      background-color: transparent;
    }

    &__img {
      overflow: hidden;
      border-radius: 12px;
      background: url("/assets/img/agency-cover.webp") no-repeat center
        center/cover;

        &.original_link{
          text-align: center;
          padding-top: 24%;
        }
    }
  }
}
.checkprofile__bar {
  position: fixed;
  z-index: 3;
  max-width: unset;
  width: 100%;
  justify-content: center;
  bottom: 0;
  background: var(--black-800);
  border-radius: 20px 20px 0 0;
  border-top: solid var(--green-main);
  @media (max-width: $mobile) {
    bottom: 76px;
  }
}

.all-photo-container {
  flex: 0 0 50%;
  display: flex;
  height: 466px;
  flex-wrap: wrap;
  overflow: auto;

  img {
    margin: 0 5px 5px 0;
  }

  ~ .model__info {
    flex: 0 0 50%;
    display: block;
  }
}

